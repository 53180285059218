import { HttpService, resolveService } from "@reco-m/core";

/**
 * 资源管理
 */
export class ResourceHttpService extends HttpService {
    constructor() {
        super("resource/resource");
    }

    /**
     * 获取资源默认配置项
     * @param id
     */
    getResourceConfig(id: number) {
        return this.httpGet(id + "/config");
    }

    /**
     * 获取空间
     * @param data
     * @returns
     */
    getSpace(data) {
        return this.httpGet("space", this.resolveRequestParams(data));
    }

    /**
     * 获取所有资源分页
     * @param data
     */
    getAllResourcePaged(data) {
        return this.httpGet(this.resolveUrl("fast-query"), this.resolveRequestParams(data));
    }

    /**
     * 获取资源占用情况
     * @param data
     */
    getResourceStatus(data: any) {
        return this.httpPost("status", data);
    }
}

/**
 * 订单管理
 */
export class ResourceOrderHttpService extends HttpService {
    constructor() {
        super("order/resource");
    }

    /**
     * 订单操作
     */
    orderOperate(data: any) {
        return this.httpPost("operate", data);
    }

    /**
     * 订单日志
     */
    getOrderLog(id: any) {
        return this.httpGet(id + "/logs");
    }

    /**
     * 取消订单
     * @param orderId
     */
    cancelOrder(orderId: any) {
        return this.httpPost(orderId + "/cancel");
    }

    /**
     * 申请重新退款
     * @param id
     * @returns
     */
    reRefundOrder(id: any) {
        return this.httpPost(id + "/again-refund");
    }

    /**
     * 获取订单总小时
     * @param data
     * @returns
     */
    todayRemainingTime(data: any) {
        return this.httpGet("order-hour-duration", this.resolveRequestParams(data));
    }
}

/**
 * 空间管理
 */
export class SpaceHttpService extends HttpService {
    constructor() {
        super("space/space");
    }

    /**
     * 获取空间位置
     * @param spaceId
     */
    getLocation(spaceId) {
        return this.httpGet("location/" + spaceId);
    }
}

/**
 * 交易管理
 */
export class PayHttpService extends HttpService {
    constructor() {
        super("cash/pay");
    }
}

export const resourceService = resolveService(ResourceHttpService);

export const myOrderService = resolveService(ResourceOrderHttpService);

export const spaceService = resolveService(SpaceHttpService);

export const payService = resolveService(PayHttpService);
