import { HttpService, resolveService } from "@reco-m/core";
export class AppModuleHttpService extends HttpService {
    protected configPromise: any;
    constructor() {
        super("app/module");
    }

    /**
     * 获取高德配置
     */
    getAMapConfig() {
        this.configPromise || (this.configPromise = this.httpGet("getConfig"));

        return this.configPromise || Promise.resolve({});
    }

    /**
     * 清除高德地图配置
     */
    clearAMapConfig() {
        delete this.configPromise;
    }

    /**
     * 重新获取高德地图配置
     */
    refreshAMapConfig() {
        return this.clearAMapConfig(), this.getAMapConfig();
    }
}
export const appModuleService = resolveService(AppModuleHttpService);
