import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";

import { CoreEffects, CoreState, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app, getSearchUrl } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { myOrderService } from "@reco-w/order-service";

import { MY_ORDER_TABS_BY_STATUS, Namespaces, OrderCommentTypeEnum, OrderStatusEnum, ResourceTypeEnum } from "./common";

export namespace myOrderModel {
    export const namespace = Namespaces.myOrder;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
            resourceType: "QB",
            orderStatus: "QB",
            currentPage: 1,
        },
        !0
    );
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getOrderListByUrl", message, props });
            } catch (e) {}
        },

        /**
         * 根据路由获取订单
         */
        *getOrderListByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl(["pi", "ps", "ro", "cs", "ros"], props);

                const resourceOrderStatus: any = MY_ORDER_TABS_BY_STATUS.find((x) => x.value === Number(params["ros"])) || { value: "QB", commentStatus: null };
                if ([OrderStatusEnum.succeeded + OrderCommentTypeEnum.comment, OrderStatusEnum.succeeded + OrderCommentTypeEnum.noComment].contains(resourceOrderStatus.value)) {
                    resourceOrderStatus.searchValue = OrderStatusEnum.succeeded;
                } else {
                    resourceOrderStatus.searchValue = resourceOrderStatus.value;
                }

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 6,
                        resourceType: params["ro"] || "QB",
                        orderStatus: resourceOrderStatus?.value,
                        commentStatus: resourceOrderStatus?.commentStatus,
                    },
                });

                const data = {
                    Key: params["k"],
                    parkId: getLocalStorage("parkId"),
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 6,
                    isValid: true,
                    orderSubType: params["ro"] === "QB" ? null : params["ro"],
                    orderStatus: resourceOrderStatus?.searchValue === "QB" ? null : resourceOrderStatus?.searchValue,
                    commentStatus: resourceOrderStatus?.commentStatus,
                };
                yield put({ type: "getOrdersAction", data, message });
            } catch (e) {
                yield call(message!.error, "getOrderListByUrl：" + e.errmsg);
            }
        },

        /**
         * 获取订单
         */
        *getOrdersAction({ message, data }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, message });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user && memberCommonState.user.currentUser;

                const result = yield call(myOrderService.getPaged, {
                    inputerId: user && user.id,
                    exceptOrderSubType: ResourceTypeEnum.parkingLot,
                    ...data,
                });
                yield put({
                    type: "input",
                    data: {
                        orderData: result.items,
                        dataSource: result,
                        total: Number(result.totalItems),
                    },
                });
            } catch (e) {
                yield call(message!.error, "getOrdersAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(myOrderModel);
