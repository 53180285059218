export const footerpark = [
    {
        type: "lblBipark",
        name: "BiPark",
        childs: [
            { url: "/BiPark.aspx?dir=1647", name: "集成服务" },
            { url: "/BiPark.aspx?dir=1648", name: "招商引资" },
            { url: "/BiPark.aspx?dir=1649", name: "资产经营" },
            { url: "/BiPark.aspx?dir=1650", name: "运营看板" },
            { url: "/BiPark.aspx?dir=1652", name: "智能应用" },
        ],
    },

    {
        type: "lblSolutions",
        name: "解决方案",
        childs: [
            { url: "/Products_and_Solutions.aspx?anchor=MTYzMg%3d%3d", name: "开发区" },
            { url: "/Products_and_Solutions.aspx?anchor=MTYzMw%3d%3d", name: "产业园区" },
            { url: "/Products_and_Solutions.aspx?anchor=MTYzNA%3d%3d", name: "大学科技园" },
            { url: "/Products_and_Solutions.aspx?anchor=MTYzNQ%3d%3d", name: "孵化器/众创空间" },
            { url: "/Products_and_Solutions.aspx?anchor=MTYzNg%3d%3d", name: "产业地产" },
        ],
    },

    {
        type: "lblAboutus",
        name: "关于我们",
        childs: [
            { url: "/about.aspx", name: "公司介绍" },
            { url: "/about.aspx", name: "动态资讯" },
            { url: "/about.aspx", name: "诚聘英才" },
            { url: "/about.aspx", name: "联系我们" },
        ],
    },
];

export const footerlink = [
    [
        { url: "https://www.lgxc.gov.cn/index.html", name: "临港新片区" },
        { url: "https://zjkxc.pudong.gov.cn/#/index", name: "张江科学城" },
        { url: "http://new.caohejing.com/index.php", name: "漕河泾开发区" },
        { url: "https://www.shlingang.com/", name: "临港集团" },
    ],
    [
        { url: "https://www.zjpark.com/", name: "张江高科" },
        { url: "http://www.pudong.gov.cn/shibo/", name: "上海世博开发区" },
        { url: "http://www.wlkjc.com/", name: "北京未来科学城" },
        { url: "http://www.china-shftz.gov.cn/Homepage.aspx", name: "中国（上海）自贸区" },
    ],
    [
        { url: "http://www.cpcu.cn/blank1.html?introid=10", name: "中文发集团" },
        { url: "http://jjkfq.nanjing.gov.cn/", name: "南京经开区" },
        { url: "http://zmxq.anqing.gov.cn/", name: "安庆筑梦新区" },
        { url: "https://www.cmsk1979.com/", name: "招商蛇口" },
    ],
    [
        { url: "http://www.saicgroup.com/chinese/index.shtml", name: "上汽集团" },
        { url: "http://www.tusincere.com/", name: "启迪协信集团" },
        { url: "https://sh.crland.com.cn/", name: "华润置地（华东大区）" },
    ],
    [
        { url: "http://www.shwmcj.cn/madeOrder/huaxinzhiye/", name: "华鑫置业（集团）" },
        { url: "http://www.pkusp.com.cn/", name: "北大科技园" },
        { url: "http://www.tjt.cn/", name: "同济大学科技园" },
    ],
    [
        { url: "http://www.marinecubepark.com/website", name: "临港海立方科技园" },
        { url: "http://www.lifesciencepark.com.cn/", name: "中关村生命科学园" },
        { url: "https://www.ypbase.com/", name: "杨浦科创" },
    ],
];

export function splitArr(arr: any = [], size = 0) {
    let arr2: any = [];
    for (let i = 0; i < arr.length; i = i + size) {
        arr2.push(arr.slice(i, i + size));
    }

    return arr2;
}
