import { registerPageFooterModel } from "@reco-w/layout-models";

import { PageFooter } from "./footer";

import { PageHeader } from "./login.header";

import "@reco-w/layout2-models";

export { PageFooter, PageHeader };

const pageLayout = client.pageLayout || (client.pageLayout = {} as any);

export function registerPageFooterBlock({ pageFooter, pageFooterModel }: any = {}) {
    registerPageFooterModel(pageFooterModel);

    (pageFooter && (pageLayout.pageFooter = pageFooter)) || pageLayout.pageFooter || (pageLayout.pageFooter = PageFooter.Page);
}

export function registerPageLayoutBlocks({ pageFooter, pageFooterModel }: any = {}) {
    registerPageFooterBlock({ pageFooter, pageFooterModel });
}
