import { router } from "dva";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";

import { loadLazyModule } from "@reco-w/core-ui";

function RouterConfig({ history }: any) {
    return (
        <ConfigProvider locale={zhCN}>
            <router.Router history={history}>
                <router.Switch>
                    <router.Route exact path="/login" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/index-e"))} />
                    <router.Route exact path="/try" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/index-try"))} />
                    <router.Route exact path="/index" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/entrance-e"))} />
                    <router.Route path="/configuration" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/configuration-e"))} />
                    <router.Redirect exact to={`/index`} />
                </router.Switch>
            </router.Router>
        </ConfigProvider>
    );
}

export default RouterConfig;
