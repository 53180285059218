/**
 * ipark bindTableName 枚举
 */
export enum IParkBindTableNameEnum {
    /**
     * 活动
     */
    activity = "bi_activity",
    /**
     * 资讯
     */
    article = "std_article",
    /**
     * 话题
     */
    circle = "std_topic",
    /**
     * 动态
     */
    post = "std_post",
    /**
     * 优惠券
     */
    coupon = "bi_coupon",
    /**
     * 服务机构
     */
    institution = "bi_service_institution",
    /**
     * 服务产品
     */
    product = "bi_service_product",
    /**
     * 会员
     */
    member = "std_member",
    /**
     * 企业认证
     */
    newCompany = "std_new_company",
    /**
     * 会员认证
     */
    certify = "std_member_authentication",
    /**
     * 订单
     */
    order = "bi_order",
    /**
     * 政策
     */
    policy = "bi_policy",
    /**
     * 问题
     */
    problem = "bi_problem",
    /**
     * 资源
     */
    resource = "bi_resource",
    /**
     * 空间
     */
    space = "std_space",
    /**
     * 评论
     */
    comment = "std_comment",
    /**
     * 工单
     */
    workorder = "std_workorder",
    /**
     * 问卷
     */
    survey = "std_survey",
    /**
     * 已回答问卷
     */
    surveyAnswer = "STDSurveyAnswer",
    /**
     * 我的话题
     */
    myCircle = "STDFollow",
    /**
     * 联系人
     */
    contact = "std_contact",

    account = "sys_account",
    /**
     * 问卷
     */
    questionnaire = "bi_questionnaire",
    /**
     * 举报
     */
    report = "std_report",
    /**
     * 服务机构入驻审核消息
     */
    biServiceAuditRecord = "bi_service_audit_record",
    /**
     * 推送消息
     */
    stdInfoReachObjectLog = "std_info_reach_object_log",
    /**
     * 粉丝关注消息
     */
    stdFollow = "std_follow",
    /**
     * 审核员工或管理员
     */
    memberAudit = "certifyslider",
    stdFlowState = "StdFlowState",
    /**
     * 资讯，仅在信息触达中使用
     */
    articleInformation = "std_article_information",
    /**
     * 工单反馈
     */
    workOrderLog = "BIWorkOrderLog",
    /**
     * 企业主页
     */
    companyHomePage = "bi_company_home_page",
    /**
     * 企业招聘
     */
    recruit = "bi_company_recruitment",
    /**
     * 企业账单
     */
    businessBill = "bi_business_bill",
    /**
     * 政策实施细则
     */
    policyImplementation = "bi_policy_implementation_detail",
}

/**
 * 共用的路由参数枚举
 */
export enum CommonSearchParamInUrlEnum {
    /**
     * 关键字
     */
    key = "k",
    /**
     * 当前页数
     */
    pageIndex = "pi",
    /**
     * 页面容量
     */
    pageSize = "ps",
    /**
     * 排序字段
     */
    sortField = "sf",
    /**
     * 排序方式（正序、倒序）
     */
    sortSequence = "sq",
    /**
     * 全局搜索类型
     */
    searchTypeEnum = "st",
    /**
     * 全局关键字
     */
    globalKey = "gk",
    /**
     * 评论的当前页数
     */
    commentPageIndex = "cpi",
    /**
     * 评论的页面容量
     */
    commentPageSize = "cps",
    /**
     * 开始时间
     */
    startDate = "sd",
    /**
     * 结束时间
     */
    endDate = "ed",
    /**
     * 标题tab值
     */
    titleTab = "tt",
    /**
     * tab值
     */
    tabIndex = "ti",
    /**
     * 子tab值
     */
    childTabIndex = "cti",
}

/**
 * 分享类型枚举
 */
export enum ShareUrlTypeEnum {
    /**
     * 活动
     */
    activity = "activity",
    /**
     * 机构
     */
    market = "market",
    /**
     * 产品
     */
    product = "product",
    /**
     * 政策
     */
    policy = "policy",
    /**
     * 文章
     */
    article = "article",
    /**
     * 资源
     */
    resource = "resource",
    /**
     * 资源
     */
    implementation = "implementation",
}

/**
 * 来源枚举
 */
export enum SourceEnum {
    /**
     * web
     */
    web = 4,
}

/**
 * 来源（文字）枚举
 */
export enum SourceTextEnum {
    /**
     * Web
     */
    web = "Web",
}
