import { EffectsMapObject, routerRedux } from "dva";

import { ReducersMapObject } from "redux";

import { freeze } from "immer";

import { CoreEffects, CoreState, CoreReducers } from "@reco-m/core";

import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";

import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { authService } from "@reco-w/auth-service";

import { Namespaces } from "./common";
import { userTrialApplyService } from "@reco-w/configuration-service";

export namespace headerModel {
    export const namespace = Namespaces.headernew;

    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * 初始化页面
         * @param { message, inputData, isPreventGetUser, judgeParkCode }
         * @param { call, put }
         */
        *initPage({ message }, { call, put }) {
            try {
                yield yield put({ type: "getCertify", message });
                yield yield put({ type: "getIsEffective", message });
            } catch (e) {
                yield call(message!.error, "initPage" + e.errmsg);
            }
        },
        /**
         * 获取认证情况
         * @param { message, isRefreshUser }
         * @param { call, put, select }
         */
        *getCertify({ message, isRefreshUser }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const member = memberCommonState.member;
                const currentUser = user.currentUser;
                yield put({ type: "input", data: { user: currentUser, member } });
            } catch (e) {
                console.log(e);
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },

        /**
         * 获取认证情况
         * @param { message, isRefreshUser }
         * @param { call, put, select }
         */
        *getIsEffective({ message }, { call, put }) {
            try {
                const user = yield call(authService.getCurrentUser);
                console.log(user);
                if (user?.currentUser?.mobile) {
                    const result = yield call(userTrialApplyService.refreshEffective, user?.currentUser?.mobile);
                    if (!result) {
                        message?.error("当前用户无访问权限");
                        yield put(routerRedux.replace(`/login`));
                        return;
                    }
                }
            } catch (e) {
                console.log(e);
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },

        /**
         * 登出
         * @param { error, success }
         * @param { call, put }
         */
        *logout({ error, success }, { call, put }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/clean` });
                yield yield put({ type: `${commonNamespaces.workorderCommon}/clean` });
                yield yield put({ type: `${commonNamespaces.consumerCommon}/clean` });

                yield put({ type: `${userNamespaces.user}/logout`, success });
            } catch (e) {
                yield call(error, "Head-logout" + e.errmsg);
            }
        },
    };
}

app.model(headerModel);
