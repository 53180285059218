import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app, deepClone, getSearchUrl } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces, NotificationTypesEnum, MailBoxTypeEnum } from "@reco-w/common-models";
import { notificationService } from "@reco-w/notice-service";

import { Namespaces } from "./common";
import { isJump } from "./utils";

export namespace noticeModel {
    export const namespace = Namespaces.notice;
    export const state: any = freeze({
        ...CoreState,
        showloading: true,
    });
    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, props }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getCertify", message });
                yield yield put({ type: "getAllNotificationSence" });
                yield put({ type: "getNotificationListByUrl", props });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取认证信息
         * @param { message }
         * @param { call, put, select }
         */
        *getCertify({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = memberCommonState.member;

                yield put({ type: "input", data: { member: member } });
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },

        /**
         * 获取消息类型
         * @param { message }
         * @param { call, put }
         */
        *getAllNotificationSence({ message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.noticeCommon}/getScene`, message });
                const noticeCommonState = yield select((state) => state[commonNamespaces.noticeCommon]);
                let scenes = [{ sceneName: "全部消息类型", id: "" }, ...deepClone(noticeCommonState!.scenes || [])],
                    sceneId = noticeCommonState!.sceneIds;

                scenes.length > 0 &&
                    scenes.forEach((x) => {
                        x.label = x.sceneName;
                        x.value = x.id;
                    });

                yield put({ type: "input", data: { sceneId, scenes } });
            } catch (e) {
                yield call(message!.error, "getAllNotificationSence：" + e.errmsg);
            }
        },

        /**
         * 根据路由获取数据
         * @param { message, props }
         * @param { call, put }
         */
        *getNotificationListByUrl({ message, props }, { call, put }) {
            try {
                const params = getSearchUrl(["pi", "ps", "sid"], props);

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || 10,
                        selectedSceneID: params["sid"] || "",
                    },
                });

                const data = {
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || 10,
                };

                yield put({
                    type: "getNotificationList",
                    sceneIDs: params["sid"] ? params["sid"] : null,
                    data,
                    message,
                });
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },

        /**
         * 获取消息
         * @param { message, data, sceneIDs }
         * @param { call, put, select }
         */
        *getNotificationList({ message, data, sceneIDs }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const state = yield select((state) => state[Namespaces.notice]);

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, message });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState!.user,
                    currentUser = user && user!.currentUser;

                const result = yield call(notificationService.getPaged, {
                    ownerId: currentUser.id,
                    notificationType: NotificationTypesEnum.unRead,
                    mailbox: MailBoxTypeEnum.mailBox2,
                    parkId: getLocalStorage("parkId"),
                    sceneId: sceneIDs ? sceneIDs : state!.sceneId,
                    ...data,
                });

                for (let i = 0; i < result?.items?.length; i++) {
                    const item = result.items[i];
                    item.isNotJump = isJump(item)?.isNotJump;
                }

                yield put({ type: "input", data: { NoticeList: result.items, totalItems: result.totalItems, currentPage: result.currentPage } });
            } catch (e) {
                yield call(message!.error, "getNotificationList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 设置已读
         * @param { message, detailId }
         * @param { call, put }
         */
        *readNotificationAction({ message, detailId, callback }, { call, put }) {
            try {
                yield call(notificationService.readNotification, detailId);
                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, "readNotificationAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 设置全部已读
         * @param { message, callback }
         * @param { call, put }
         */
        *readAllNotificationAction({ message, callback }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.notice]),
                    sceneId = state!.selectedSceneID || null;

                yield call(notificationService.readAllNotification, { notificationType: NotificationTypesEnum.unRead, sceneId });

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(message!.error, "readAllNotificationAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(noticeModel);
