import React from "react";

import { Modal } from "antd";

import { template } from "@reco-m/core";

import { ViewComponent } from "@reco-w/core-ui";

import { headerModel, Namespaces } from "@reco-w/layout2-models";

export namespace PageHeader {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {}

    export interface IState extends ViewComponent.IState, headerModel.StateType {
        navState?: any;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.headernew;

        componentDidMount() {
            this.dispatch({ type: "initPage" });
        }
        /**
         * 渲染已登录时的展示按钮
         * @returns auth login
         */
        renderAuthLogin(): React.ReactNode {
            let { state } = this.props;
            const user = state!.user;
            return (
                <>
                    <a className="pr5">{user?.nickName || "请设置昵称"}</a>丨
                    <a
                        className="pr0 pl5"
                        onClick={() =>
                            this.dispatch({
                                type: `logout`,
                                success: () => this.goTo(`/login`),
                                error: (e) => Modal.error({ title: "消息提醒", content: e }),
                            })
                        }
                    >
                        注销
                    </a>
                </>
            );
        }

        /**
         * 渲染登录按钮
         * @returns
         */
        renderLogin(): React.ReactNode {
            return this.isAuth() ? this.renderAuthLogin() : null;
        }

        render(): React.ReactNode {
            return this.renderLogin();
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.headernew]);
}
