import { HttpService, resolveService } from "@reco-m/core";

export class EmpowerHttpService extends HttpService {
    protected currentUserPromise: any;

    constructor() {
        super("empower/empower-solution");
    }

    /**
     * 配置中心-我的解决方案-列表查询
     * @param data
     * @returns
     */
    getSimpleAllSolutionPage(data?) {
        return this.httpGet("get-simple-all-solution-page", this.resolveRequestParams(data));
    }
    /**
     * 删除我的解决方案
     * @param id
     * @returns
     */
    deleteProgramme(id) {
        return this.httpDelete("" + id);
    }

    /**
     * 修改我的解决方案
     * @param id
     * @param data
     * @returns
     */
    modifyProgramme(data: any) {
        return this.httpPut("", data);
    }
    /**
     * 登录后首页-解决方案&终端列表查询
     * @param data
     * @returns
     */
    getSimpleSolutionPage(data?) {
        return this.httpGet("get-simple-solution-page", this.resolveRequestParams(data));
    }
}

export const empowerService = resolveService(EmpowerHttpService);

export class UserTrialApplyHttpService extends HttpService {
    protected isEffectivePromise: any;
    constructor() {
        super("user/trial-apply");
    }

    /**
     * 申请试用-获取验证码
     * @param data
     * @returns
     */
    sendVerifyCode(mobile) {
        return this.httpGet("send-verify-code/" + mobile);
    }

    /**
     * 判断试用权限
     * @param data
     * @returns
     */
    isEffective(mobile) {
        this.isEffectivePromise || (this.isEffectivePromise = this.httpGet("is-effective/" + mobile));
        return this.isEffectivePromise || Promise.resolve({});
    }

    clearEffective() {
        delete this.isEffectivePromise;
    }

    /**
     * 刷新试用权限
     * @param data
     * @returns
     */
    refreshEffective(mobile) {
        return this.clearEffective(), this.isEffective(mobile);
    }

    /**
     * 判断是否能申请试用
     * @param data
     * @returns
     */
    canApply(mobile) {
        return this.httpGet("can-apply/" + mobile);
    }

    post(data?) {
        return this.httpPost("", data);
    }
}

export const userTrialApplyService = resolveService(UserTrialApplyHttpService);

// /empower/empower-extremity 终端
export class EmpowerExtremityHttpService extends HttpService {
    protected currentUserPromise: any;

    constructor() {
        super("empower/empower-extremity");
    }

    /**
     * 终端-列表查询
     * @param data
     * @returns
     */
    getSimpleAllSolutionPage(data?) {
        return this.httpGet("get-simple-all-solution-page", this.resolveRequestParams(data));
    }

    /** 获取终端类型 */
    getEmpowerExtremityTypeEnum(data?) {
        return this.httpGet("get-empower-extremity-type-enum", this.resolveRequestParams(data));
    }
    /** 获取拥有授权的系统模块树 */
    getSystemAppProduct(data?) {
        return this.httpGet("get-system-app-product", this.resolveRequestParams(data));
    }
    /** 获取选中的模块Code */
    getSimpleAppProduct(data?) {
        return this.httpGet("get-simple-app-product", this.resolveRequestParams(data));
    }

    /**
     * 删除我的终端
     * @param id
     * @returns
     */
    deleteExtremity(id) {
        return this.httpDelete("" + id);
    }

    /**
     * 修改我的终端
     * @param id
     * @param data
     * @returns
     */
    modifyExtremity(id, data: any) {
        return this.httpPut("" + id, data);
    }
}

export const empowerExtremityService = resolveService(EmpowerExtremityHttpService);

export class EmpowerRoleHttpService extends HttpService {
    protected currentUserPromise: any;

    constructor() {
        super("empower/empower-role");
    }

    /**
     * 获取当前端下某个用户拥有的应用权限
     * @param extremityid
     * @returns
     */
    getRoleResourcesListByUserId(extremityId) {
        return this.httpGet(`role/${extremityId}`);
    }
}

export const empowerRoleService = resolveService(EmpowerRoleHttpService);
